import { all, takeEvery, put, call } from "redux-saga/effects";
import { history } from "../../index";
import { login, currentAccount, logout } from "../../services/users";
import actions from "./actions";

export function* LOGIN({ payload }) {
  const { contact, password } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
      wrong: false,
    },
  })
  const success = yield call(login, contact, password)
  if (success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
  }
  else{
    yield put({
      type: "user/SET_STATE",
      payload: {
        loading: false,
        wrong:true,
        login:true,
      },
    });
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(currentAccount)
  if (response) {
    console.log(response)
    const { id, contact, email, photoURL: avatar,role,authorized, name, userdata,token } = response;
    yield put({
      type: "user/SET_STATE",
      payload: {
        id,
        contact,
        email,
        avatar,
        role,
        authorized,
        name,
        userdata,
        token
      },
    });
  }
  yield put({
    type: "user/SET_STATE",
    payload: {
      loading: false,
      login: false,
    },
  });
}

export function* LOGOUT() {
  yield call(logout);
  yield localStorage.removeItem("token");
  yield localStorage.removeItem("user");
  yield put({
    type: "user/SET_STATE",
    payload: {
      id: "",
      name: "",
      role: "",
      contact: "",
      email: "",
      avatar: "",
      authorized: false,
      loading: false,
      userdata: {},
      wrong: false,
      login: false,
    },
  });
  yield history.push("/login");
  yield window.location.reload()
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ]);
}
