import actions from "./actions";
import store from "store";
const initialState = {
  workPlace: "",
  role: "",
  contact: "",
  birthday: "",
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
