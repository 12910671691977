import "bootstrap/dist/css/bootstrap.min.css";
import "./App/Assets/css/General.scss";
import "./App/Assets/css/style.scss";

import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import { routerMiddleware } from "connected-react-router";
import axios from "axios";

import CacheBuster from "./CacheBuster";
import reducers from "./redux/reducers";
import sagas from "./redux/sagas";
import Router from "./routes";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18next";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

axios.defaults.baseURL = "https://edusheet.letseduvate.com/qbox";
axios.defaults.headers.common.Authorization = ``;
axios.defaults.headers.post["Content-Type"] = "application/json";

// middlewared
const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk, sagaMiddleware, routeMiddleware];

const store = createStore(
  reducers(history),
  compose(applyMiddleware(...middlewares))
);

sagaMiddleware.run(sagas);
ReactDOM.render(
  <CacheBuster>
    {({ loading, isLatestVersion, refreshCacheAndReload }) => {
      // console.log(loading)
      // console.log(isLatestVersion)
      // if (loading) return null
      if (!loading && !isLatestVersion) {
        refreshCacheAndReload();
      }

      return (
        <Provider store={store}>
          <I18nextProvider i18n={i18n}>
            <Router history={history} />
          </I18nextProvider>
        </Provider>
      );
    }}
  </CacheBuster>,

  document.getElementById("root")
);
export { store, history };
