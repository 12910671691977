import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import user from "./user/reducers";
import settings from "./settings/reducers";
import signUp from "./signUp/reducer"
export default (history) =>
  combineReducers({
    router: connectRouter(history),
    user,
    settings,
    signUp,
  });
