import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Error404 from "../../Assets/images/error404.png";
import "../../Assets/css/Error404.scss";
import { withRouter } from 'react-router-dom';

class PageNotFound extends Component {
  render() {
    return (
      <>
        <Helmet title="Error 404 | Page not found" />

        {/* Services Section */}
        <div className="bg-light error-404-container row justify-content-center">
          <div className="col-12 col-md-6 col-sm-8 d-flex flex-column justify-content-center align-items-center">
            <img
              src={Error404}
              slt="error-404"
              className="error-pic"
              width="50%"
            />
            <span className="error-text my-5 text-center">
              The page you are looking for might have been removed, had its name
              changed or temporarily unavailable
            </span>
            <button className="btn btn-primary w-50 home-button" onClick={()=>this.props.history.push("/")}>
              GO TO HOMEPAGE
            </button>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(PageNotFound);
