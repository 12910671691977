import React, { useEffect, useState } from "react";
import Logo from "../../../Assets/images/FinalLogo.png";
import "../../../Assets/css/Navbar.scss";
import {
  BsListUl,
  BsBarChart,
  BsPeopleFill,
  BsBell,
  BsPersonCircle,
} from "react-icons/bs";
import {
  FiSettings,
  FiGlobe,
  FiLogOut,
  FiMenu,
  FiHome,
  FiArchive,
  FiTrendingUp,
  FiUsers,
  FiUser,
  FiChevronDown,
  FiLogIn,
} from "react-icons/fi";
import { VscDiffAdded } from "react-icons/vsc";
import { Link, useHistory, Redirect } from "react-router-dom";
import $ from "jquery";
import { FaTimes } from "react-icons/fa";
import { Modal, Button, Row, Col, Card, Spinner } from "react-bootstrap";
import axios from "axios";
import { connect } from "react-redux";
import AuthVisible from "../../../../global/AuthVisible";
import UnAuthVisible from "../../../../global/UnAuthVisible";
import CustomToast from "../../MyComponents/Toast";
import Visibility from "../../../../global/Visibility";
import CreateQuizTemplateModal from "../../MyComponents/CreateQuizTemplateModal";

const mapStateToProps = ({ dispatch, user }) => ({
  dispatch,
  user,
});

const Navbar = ({ dispatch, user }) => {
  const { roles } = user?.userdata;
  let history = useHistory();
  const toggleSidebar = () => {
    $(".sidebar-content").toggleClass("sidebar-content-open");
    $(".black-overlay").toggleClass("black-overlay-open");
  };
  const [templatess, settemplates] = useState([]);
  const [redId, setredId] = useState(0);
  const [redtoggle, setredtoggle] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [show, setShow] = useState(false);
  const [showType, setShowType] = useState("denger");
  const [showMessage, setShowMessage] = useState("");

  useEffect(() => {
    // console.log(user, "userData in header");
  }, []);

  const handleSignIn = (e) => {
    console.log("hello");
    e.preventDefault();
    if (localStorage.getItem("token")) {
      localStorage.clear();
      dispatch({
        type: "user/SET_STATE",
        payload: {
          authorized: false,
        },
      });
      history.push("/login");
    }
    if (localStorage.getItem("token") === null) {
      history.push("/login");
    }
  };
  const [quizmodal, setquizModal] = useState(false);

  const createQuiz = (id) => {
    if (id === null) {
      setShow(true);
      setShowType("danger");
      setShowMessage("Please select template");
      return;
    }
    setLoading(true);
    axios
      .get(`apiV1/copy-test/${id}/`)
      .then((res) => {
        if (res.data) {
          setredId(res.data.id);
        }
      })
      .then(() => {
        setredtoggle(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const fetchData = () => {
    axios.get("apiV1/test/?template=1").then((res) => {
      if (res.data) {
        settemplates(res.data);
        setquizModal(true);
      }
    });
  };

  const handleProfile = (e) => {
    console.log("hello profile");
    e.preventDefault();
    history.push("/user-profile");
  };

  const setCreateQuizModalClose = () => {
    setquizModal(false);
  };

  return (
    <>
      <div className="navbar sticky-top">
        {redtoggle ? <Redirect to={`/create-quiz/${redId}`} /> : null}
        <div className="small-navbar-content w-100 justify-content-between m-2 position-relative">
          <div className="m-2 my-auto" onClick={toggleSidebar}>
            <FiMenu size={22} />
          </div>
          <AuthVisible>
            <div className="m-2 my-auto m-3">
              <FiMenu size={22} color={"#fff"} />
            </div>
          </AuthVisible>

          <div className="edusheet-logo">
            <Link to="/">
              <img src={Logo} alt="" style={{ height: "2rem" }} />
            </Link>
          </div>
          <AuthVisible>
            <Visibility rolesArray={[1, 2, 3, 5]}>
              <div className="m-2 my-auto">
                <button className="btn btn-sm" onClick={fetchData}>
                  <VscDiffAdded size={22} />
                </button>
              </div>
            </Visibility>
            {/* <div className="m-2 my-auto">
              <BsBell size={22} />
            </div> */}
          </AuthVisible>
          <UnAuthVisible>
            <div className="m-2 my-auto m-2">
              <button
                className="btn btn-sm btn-primary"
                onClick={() => history.push("/login")}
              >
                Login <FiLogIn className="ml-1" size={22} />
              </button>
              {/* <FiLogIn size={22} onClick={()=>history.push("/login")} /> */}
            </div>
          </UnAuthVisible>
        </div>
        <div className="black-overlay" onClick={toggleSidebar}></div>

        <div className="navbar-content contianer-fluid container-lg ">
          <div className="navbar-content-left">
            <Link to="/" className="navbar-brand">
              <img src={Logo} alt="" style={{ height: "2rem" }} />
            </Link>
            <ul className="navbar-nav mr-auto">
              <AuthVisible>
                <Visibility rolesArray={[1, 2, 3, 5]}>
                  <li className="nav-item">
                    <Link to="/" className="nav-link mx-3">
                      <span className="my-auto">
                        <FiHome />
                      </span>
                      <span className="my-auto th-14 pl-1">Home</span>
                    </Link>
                  </li>
                </Visibility>
                {/* <li className="nav-item">
                <Link to="" className="nav-link mx-3">
                  <span className="my-auto">
                    <FiArchive />
                  </span>
                  <span className="my-auto th-14 pl-1">Library</span>
                </Link>
              </li> */}
                <Visibility rolesArray={[1, 2, 3, 5]}>
                  <li className="nav-item">
                    <Link to="/reports" className="nav-link mx-3">
                      <span className="my-auto">
                        <FiTrendingUp />
                      </span>
                      <span className="my-auto th-14 pl-1">Reports</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/groups" className="nav-link mx-3">
                      <span className="my-auto">
                        <FiUsers />
                      </span>
                      <span className="my-auto th-14 pl-1">Groups</span>
                    </Link>
                  </li>
                </Visibility>
                <Visibility rolesArray={[4]}>
                  <li className="nav-item">
                    <Link
                      to="/student-dashboard/groups"
                      className="nav-link mx-3"
                    >
                      <span className="my-auto">
                        <FiUsers />
                      </span>
                      <span className="my-auto th-14 pl-1">Groups</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/student-dashboard/individual-test"
                      className="nav-link mx-3"
                    >
                      <span className="my-auto">
                        <FiTrendingUp />
                      </span>
                      <span className="my-auto th-14 pl-1">
                        Individual Test
                      </span>
                    </Link>
                  </li>
                </Visibility>
              </AuthVisible>
              <li className="nav-item">
                <Link to="/games-worksheet" className="nav-link mx-3">
                  <span className="my-auto">
                    <FiUsers />
                  </span>
                  <span className="my-auto th-14 pl-1">Game-Worksheet</span>
                </Link>
              </li>
            </ul>
          </div>

          <div className="navbar-content-right">
            <AuthVisible>
              <Visibility rolesArray={[1, 2, 3, 5]}>
                <button
                  className="btn btn-secondary th-white mx-3"
                  onClick={fetchData}
                >
                  <b>Create</b>
                </button>
              </Visibility>
              {/* <Visibility rolesArray={[4]}>
                <button
                  className="btn btn-secondary th-white mx-3"
                  // onClick={fetchData}
                >
                  <b>Groups</b>
                </button>
              </Visibility> */}
              <div className="dropdown dropdown-pull-right">
                <div
                  className="icon-button dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  type="button"
                >
                  <FiUser />
                  {/* <FiChevronDown /> */}
                </div>
                <div
                  className="dropdown-menu my-3"
                  aria-labelledby="dropdownMenuButton"
                >
                  {/* <span
                  className="dropdown-item font-weight-bold"
                  onClick={(e) => handleProfile(e)}
                  role="button"
                >
                  <FiGlobe /> &nbsp; Change Language
                </span> */}
                  <span
                    className="dropdown-item font-weight-bold"
                    onClick={(e) => handleProfile(e)}
                    role="button"
                  >
                    <FiSettings /> &nbsp;Settings
                  </span>
                  <span
                    className="dropdown-item font-weight-bold"
                    onClick={(e) => handleSignIn(e)}
                    role="button"
                  >
                    <FiLogOut /> &nbsp;{" "}
                    {localStorage.getItem("token") === null
                      ? "Sign In"
                      : "Sign Out"}
                  </span>
                </div>
              </div>
            </AuthVisible>
            <UnAuthVisible>
              {/* <div className="icon-button bg-light my-auto th-14 py-2 px-3 rounded-lg">
                <FiGlobe />
                <span className="pl-2 th-12 my-auto">English</span>
              </div> */}
              <button
                className="btn btn-sm btn-primary"
                onClick={() => history.push("/login")}
              >
                Sign In
              </button>
              <button
                className="btn btn-sm btn-secondary ml-2"
                onClick={() => history.push("/signup")}
              >
                Sign Up
              </button>
            </UnAuthVisible>
          </div>
        </div>

        <div className="sidebar-content">
          <div className="sidebar-content-cross">
            <div className="sidebar-content-cross1" onClick={toggleSidebar}>
              <FaTimes />
            </div>
          </div>

          {/* <div className="user-signup-sidebar pt-5">
          <BsPersonCircle style={{ fontSize: "28px" }} />{" "}
          <Link to="/login">Login /</Link> <Link to="/signup"> Sign Up</Link>
        </div> */}
          {/* <hr /> */}
          <div className="pt-5">
            <AuthVisible>
              {/* <div className="sidebar-nav-link">
              <Link to="">
                <BsListUl /> &nbsp;&nbsp;Library
              </Link>
            </div> */}
              <Visibility rolesArray={[1, 2, 3, 5]}>
                <div className="sidebar-nav-link">
                  <Link to="/reports">
                    <BsBarChart /> &nbsp;&nbsp;Reports
                  </Link>
                </div>
                <div className="sidebar-nav-link">
                  <Link to="/groups">
                    <BsPeopleFill /> &nbsp;&nbsp;Groups
                  </Link>
                </div>
              </Visibility>
            </AuthVisible>
            <div className="sidebar-nav-link">
              <Link to="/games-worksheet">
                <BsBarChart /> &nbsp;&nbsp;Games-Worksheet
              </Link>
            </div>
            <div
              className="sidebar-nav-link"
              onClick={(e) => handleSignIn(e)}
              role="button"
            >
              <FiLogOut /> &nbsp;{" "}
              {localStorage.getItem("token") === null ? "Sign In" : "Sign Out"}
            </div>
          </div>
          {/* <hr />
          <div className="sidebar-share-btn">
            <h6>Share</h6>
          </div>
          <hr /> */}
        </div>
        <CreateQuizTemplateModal
          showQuiz={quizmodal}
          handleClose={setCreateQuizModalClose}
        />
      </div>
      <CustomToast
        show={show}
        setShow={setShow}
        bg={showType}
        msg={showMessage}
      />
    </>
  );
};

export default connect(mapStateToProps)(Navbar);
