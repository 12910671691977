import React from "react";
import { connect } from "react-redux";

const mapStateToProps = ({ dispatch, user }) => ({
  dispatch,
  user,
});

class AuthVisible extends React.Component {
  render() {
    const { children } = this.props;
    const authorized = this.props.user.authorized;
    // console.log("Authorized:", this.props.user.authorized);
    const AuthorizedChildren = () => {
      // if user not equal needed role and if component is a page - make redirect to needed route
      if (authorized) {
        // notification.error({
        //   message: 'Unauthorized Access',
        //   description: 'You have no rights to access this page!',
        // })
        return <></>;
      }
      // if access is successful render children
      return <>{children}</>;
    };
    return AuthorizedChildren();
  }
}

export default connect(mapStateToProps)(AuthVisible);
