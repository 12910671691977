import actions from "./actions";
import store from "store";
const initialState = {
  id: store.get("user"),
  name: "",
  role: "",
  contact: "",
  email: "",
  avatar: "",
  authorized: false,
  loading: false,
  userdata: {},
  wrong: false,
  login: false,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
