import axios from "axios";
import setAuthToken from "../global/setAuthToken";
import store from "store";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

export async function login(contact1, password1) {
  const value = { email: contact1, password: password1 };
  return axios
    .post(`/token-auth/`, value)
    .then((response) => {
      if (response.status === 200) {
        localStorage.setItem("token", response.data.token);
        setAuthToken(response.data.token);
        store.set("user", response.data.user.id);
        // axios.defaults.headers.common.Authorization = `JWT ${response.data.token}`;

        return response.data;
      } else if (response.status !== 200) {
        return false;
      } else {
        return false;
      }
    })
    .catch((error) => {
      return false;
    });
}

export async function currentAccount() {
  let authorized1 = false;
  return axios
    .get(`/apiV1/user/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    })
    .then((response) => {
      authorized1 = true;
      setAuthToken(localStorage.getItem("token"));
      const { data } = response;
      return {
        id: data.id,
        authorized: authorized1,
        contact: data.contact,
        role: data.roles,
        email: data.email,
        name: data.first_name,
        token: localStorage.getItem("token"),
        userdata: data,
      };
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function logout() {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
}
