import React, { useState } from "react";
import { Toast } from "react-bootstrap";
import { BsCheck2Circle, BsExclamationCircle } from "react-icons/bs";

const CustomToast = ({ show, setShow, bg, msg }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: "10px",
        right: "10px",
        zIndex: "9999999999",
      }}
    >
      <Toast
        onClose={() => setShow(false)}
        show={show}
        delay={2000}
        autohide
        bg={bg}
      >
        <Toast.Body
          className={
            "th-white " + (bg === "success" ? "th-bg-green" : "th-bg-red")
          }
        >
          {bg === "success" ? (
            <BsCheck2Circle size="25px" />
          ) : (
            <BsExclamationCircle size="25px" />
          )}
          <span className="pl-2">{msg}</span>
        </Toast.Body>
      </Toast>
    </div>
  );
};

export default CustomToast;
