import React, { Fragment, lazy, Suspense } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Header from "./Components/Layout/Header";
import PageNotFound from "./Screens/PageNotFound";

const routes = [
  {
    path: "/",
    exact: true,
    Component: lazy(() => import("./Screens/Library/index")),
  },
  {
    path: "/game-test/1",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game1")),
  },
  {
    path: "/game-test/2",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game2")),
  },
  {
    path: "/game-test/3",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game3")),
  },
  {
    path: "/game-test/4",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game4")),
  },
  {
    path: "/game-test/5",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game5")),
  },
  {
    path: "/game-test/6",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game6")),
  },
  {
    path: "/game-test/7",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game7")),
  },
  {
    path: "/game-test/8",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game8")),
  },
  {
    path: "/game-test/9",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game9")),
  },
  {
    path: "/game-test/10",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game10")),
  },
  {
    path: "/game-test/11",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game11")),
  },
  {
    path: "/game-test/12",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game12")),
  },
  {
    path: "/game-test/13",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game13")),
  },
  {
    path: "/game-test/14",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game14")),
  },
  {
    path: "/game-test/15",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game15")),
  },
  {
    path: "/game-test/16",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game16")),
  },
  {
    path: "/game-test/17",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game17")),
  },
  {
    path: "/game-test/18",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game18")),
  },
  {
    path: "/game-test/19",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game19")),
  },
  {
    path: "/game-test/20",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game20")),
  },
  {
    path: "/game-test/21",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game21")),
  },
  {
    path: "/game-test/22",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game22")),
  },
  {
    path: "/game-test/23",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game23")),
  },
  {
    path: "/game-test/24",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game24")),
  },
  {
    path: "/game-test/25",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game25")),
  },
  {
    path: "/game-test/26",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game26")),
  },
  {
    path: "/game-test/27",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game27")),
  },
  {
    path: "/game-test/28",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game28")),
  },

  {
    path: "/game-test/29",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game29")),
  },
  {
    path: "/game-test/30",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game30")),
  },
  {
    path: "/game-test/31",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game31")),
  },
  {
    path: "/game-test/32",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game32")),
  },
  {
    path: "/game-test/33",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game33")),
  },
  {
    path: "/game-test/34",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game34")),
  },
  {
    path: "/game-test/35",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game35")),
  },
  {
    path: "/game-test/36",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game36")),
  },
  {
    path: "/game-test/37",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game37")),
  },
  {
    path: "/game-test/38",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game38")),
  },
  {
    path: "/game-test/39",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game39")),
  },
  {
    path: "/game-test/40",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game40")),
  },
  {
    path: "/game-test/41",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game41")),
  },
  {
    path: "/game-test/42",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game42")),
  },
  {
    path: "/game-test/43",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game43")),
  },
  {
    path: "/game-test/44",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game44")),
  },
  {
    path: "/game-test/45",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game45")),
  },
  {
    path: "/game-test/46",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game46")),
  },
  {
    path: "/game-test/47",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game47")),
  },
  {
    path: "/game-test/48",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game48")),
  },
  {
    path: "/game-test/49",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game49")),
  },
  {
    path: "/game-test/50",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game50")),
  },
  {
    path: "/game-test/51",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game51")),
  },
  {
    path: "/game-test/52",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game52")),
  },
  {
    path: "/game-test/53",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game53")),
  },
  {
    path: "/game-test/54",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game54")),
  },
  {
    path: "/game-test/55",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game55")),
  },
  {
    path: "/game-test/56",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game56")),
  },
  {
    path: "/game-test/57",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game57")),
  },
  {
    path: "/game-test/58",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game58")),
  },
  {
    path: "/game-test/59",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game59")),
  },
  {
    path: "/game-test/60",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game60")),
  },
  {
    path: "/game-test/61",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game61")),
  },
  {
    path: "/game-test/62",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game62")),
  },
  {
    path: "/game-test/63",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game63")),
  },
  {
    path: "/game-test/64",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game64")),
  },
  {
    path: "/game-test/65",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game65")),
  },
  {
    path: "/game-test/66",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game66")),
  },
  {
    path: "/game-test/67",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game67")),
  },
  {
    path: "/game-test/68",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game68")),
  },
  {
    path: "/game-test/69",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game69")),
  },
  {
    path: "/game-test/70",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game70")),
  },
  {
    path: "/game-test/71",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game71")),
  },
  {
    path: "/game-test/72",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game72")),
  },
  {
    path: "/game-test/73",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game73")),
  },
  {
    path: "/game-test/74",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game74")),
  },
  {
    path: "/game-test/75",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game75")),
  },
  {
    path: "/game-test/76",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game76")),
  },
  {
    path: "/game-test/77",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game77")),
  },
  {
    path: "/game-test/78",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game78")),
  },
  {
    path: "/game-test/79",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game79")),
  },
  {
    path: "/game-test/80",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game80")),
  },
  {
    path: "/game-test/81",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game81")),
  },
  {
    path: "/game-test/82",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game82")),
  },
  {
    path: "/game-test/83",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game83")),
  },
  {
    path: "/game-test/84",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game84")),
  },
  {
    path: "/game-test/85",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game85")),
  },
  {
    path: "/game-test/86",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game86")),
  },
  {
    path: "/game-test/90",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game90")),
  },

{
  path:"/game-test/92",
  exact:true,
  Component:lazy(()=> import("./Screens/GameTest/Game92")),
},

{
  path:"/game-test/93",
  exact:true,
  Component:lazy(()=> import("./Screens/GameTest/Game93")),
},
{
  path:"/game-test/94",
  exact:true,
  Component:lazy(()=> import("./Screens/GameTest/Game94")),
},

{
    path: "/game-test/87",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game87")),
  },
  {
    path: "/game-test/88",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game88")),
  },
  {
    path: "/game-test/89",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game89")),
  },
  {
    path: "/game-test/91",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game91")),
  },
  {
    path: "/game-test/95",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game95")),
  },
  {
    path: "/game-test/96",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game96")),
  },
  {
    path: "/game-test/97",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game97")),
  },
  {
    path: "/game-test/98",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game98")),
  },
  {
    path: "/game-test/99",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game99")),
  },
  {
    path: "/game-test/100",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game100")),
  },
  {
    path: "/game-test/101",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game101")),
  },
  {
    path: "/game-test/102",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game102")),
  },
  {
    path: "/game-test/103",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game103")),
  },
  {
    path: "/game-test/104",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game104")),
  },
  {
    path: "/game-test/105",
    exact: true,
    Component: lazy(() => import("./Screens/GameTest/Game105")),
  },
  {
    path: "/cake",
    exact: true,
    Component: lazy(() => import("./Screens/Cake")),
  },
  {
    path: "/home-page",
    exact: true,
    Component: lazy(() => import("./Screens/HomePage")),
  },
  {
    path: "/edusheet-details/:edusheetId",
    exact: true,
    Component: lazy(() => import("./Screens/Library/EdusheetDetails")),
  },
  {
    path: "/aboutus",
    exact: true,
    Component: lazy(() => import("./Screens/Home/index")),
  },
  {
    path: "/login",
    exact: true,
    Component: lazy(() => import("./Screens/Login/index")),
  },
  {
    path: "/account-activated",
    exact: true,
    Component: lazy(() => import("./Screens/Login/AccountActivated")),
  },
  {
    path: "/reset-password",
    exact: true,
    Component: lazy(() => import("./Screens/ResetPassword/index")),
  },
  {
    path: "/reset-password-confirm",
    exact: true,
    Component: lazy(() => import("./Screens/ResetPassword/confirmPassword")),
  },
  {
    path: "/signup",
    exact: true,
    Component: lazy(() => import("./Components/Layout/SignUp/AccountType")),
  },
  {
    path: "/signup/teacher",
    exact: true,
    Component: lazy(() =>
      import("./Components/Layout/SignUp/AccountType/Teacher")
    ),
  },
  {
    path: "/signup/personal",
    exact: true,
    Component: lazy(() =>
      import("./Components/Layout/SignUp/AccountType/Personal")
    ),
  },
  {
    path: "/register",
    exact: true,
    Component: lazy(() => import("./Screens/Signup/index")),
  },
  {
    path: "/signup/birthday",
    exact: true,
    Component: lazy(() =>
      import("./Components/Layout/SignUp/AccountType/Birthday")
    ),
  },
  {
    path: "/signup/username",
    exact: true,
    Component: lazy(() =>
      import("./Components/Layout/SignUp/AccountType/Username")
    ),
  },
  {
    path: "/groups",
    exact: true,
    Component: lazy(() => import("./Screens/Groups/index")),
  },
  {
    path: "/student-dashboard/groups",
    exact: true,
    Component: lazy(() => import("./Screens/StudentDashboard/StudentGroups")),
  },
  {
    path: "/student-dashboard/individual-test",
    exact: true,
    Component: lazy(() => import("./Screens/StudentDashboard/IndividualTest")),
  },
  {
    path: "/groups-content/:groupId",
    exact: true,
    Component: lazy(() => import("./Screens/Groups/GroupContent")),
  },
  {
    path: "/user-profile",
    exact: true,
    Component: lazy(() => import("./Screens/UserProfile")),
  },
  {
    path: "/reports",
    exact: true,
    Component: lazy(() => import("./Screens/Reports/index")),
  },
  {
    path: "/reports-bin",
    exact: true,
    Component: lazy(() => import("./Screens/Reports/ReportTrash/index")),
  },
  {
    path: "/reports-details/:id",
    exact: true,
    Component: lazy(() => import("./Screens/Reports/ReportsDetails/index")),
  },
  {
    path: "/reports-podium/:reportId",
    exact: true,
    Component: lazy(() => import("./Screens/Reports/ReportPodium")),
  },
  {
    path: "/games-worksheet",
    exact: true,
    Component: lazy(() => import("./Screens/Games")),
  },
  {
    path: "/worksheet-tag/:tagId",
    exact: true,
    Component: lazy(() => import("./Screens/Games")),
  },
  {
    path: "/worksheet/:worksheetId",
    exact: true,
    Component: lazy(() => import("./Screens/Games/Worksheet")),
  },
  {
    path: "/create-quiz",
    exact: true,
    Component: lazy(() => import("./Screens/Createedusheet/index")),
  },
  {
    path: "/create-quiz/:id",
    exact: true,
    Component: lazy(() => import("./Screens/Createedusheet/index")),
  },
  {
    path: "/preview-test/:testId",
    exact: true,
    Component: lazy(() => import("./Screens/Previewtest/index")),
  },
  {
    path: "/start-test/:testId",
    exact: true,
    Component: lazy(() => import("./Screens/Taketest/index")),
  },
];

const mapStateToProps = ({ user }) => ({ user });

class App extends React.Component {
  render() {
    return (
      <Fragment>
        <Helmet titleTemplate="EduSheet | %s" title="Title" />
        <Switch>
          {routes.map(({ path, Component, exact }) => (
            <Route
              path={path}
              key={path}
              exact={exact}
              render={(match) => {
                return (
                  <Suspense fallback={null}>
                    {path === "/create-quiz/:id" ||
                    path === "/preview-test/:testId" ||
                    path === "/start-test/:testId" ||
                    path === "/game-test/1" ||
                    path === "/game-test/2" ||
                    path === "/game-test/3" ||
                    path === "/game-test/4" ||
                    path === "/game-test/5" ||
                    path === "/game-test/6" ||
                    path === "/game-test/7" ||
                    path === "/game-test/8" ||
                    path === "/game-test/9" ||
                    path === "/game-test/10" ||
                    path === "/game-test/11" ||
                    path === "/game-test/12" ||
                    path === "/game-test/13" ||
                    path === "/game-test/14" ||
                    path === "/game-test/15" ||
                    path === "/game-test/16" ||
                    path === "/game-test/17" ||
                    path === "/game-test/18" ||
                    path === "/game-test/19" ||
                    path === "/game-test/20" ||
                    path === "/game-test/21" ||
                    path === "/game-test/22" ||
                    path === "/game-test/23" ||
                    path === "/game-test/24" ||
                    path === "/game-test/25" ||
                    path === "/game-test/26" ||
                    path === "/game-test/27" ||
                    path === "/game-test/28" ||
                    path === "/game-test/29" ||
                    path === "/game-test/30" ||
                    path === "/game-test/31" ||
                    path === "/game-test/32" ||
                    path === "/game-test/33" ||
                    path === "/game-test/34" ||
                    path === "/game-test/35" ||
                    path === "/game-test/36" ||
                    path === "/game-test/37" ||
                    path === "/game-test/38" ||
                    path === "/game-test/39" ||
                    path === "/game-test/40" ||
                    path === "/game-test/41" ||
                    path === "/game-test/42" ||
                    path === "/game-test/43" ||
                    path === "/game-test/44" ||
                    path === "/game-test/45" ||
                    path === "/game-test/46" ||
                    path === "/game-test/47" ||
                    path === "/game-test/48" ||
                    path === "/game-test/49" ||
                    path === "/game-test/50" ||
                    path === "/game-test/51" ||
                    path === "/game-test/52" ||
                    path === "/game-test/53" ||
                    path === "/game-test/54" ||
                    path === "/game-test/55" ||
                    path === "/game-test/56" ||
                    path === "/game-test/57" ||
                    path === "/game-test/58" ||
                    path === "/game-test/59" ||
                    path === "/game-test/60" ||
                    path === "/game-test/61" ||
                    path === "/game-test/62" ||
                    path === "/game-test/63" ||
                    path === "/game-test/64" ||
                    path === "/game-test/65" ||
                    path === "/game-test/66" ||
                    path === "/game-test/67" ||
                    path === "/game-test/68" ||
                    path === "/game-test/69" ||
                    path === "/game-test/70" ||
                    path === "/game-test/71" ||
                    path === "/game-test/72" ||
                    path === "/game-test/73" ||
                    path === "/game-test/74" ||
                    path === "/game-test/75" ||
                    path === "/game-test/76" ||
                    path === "/game-test/77" ||
                    path === "/game-test/78" ||
                    path === "/game-test/79" ||
                    path === "/game-test/80" ||
                    path === "/game-test/81" ||
                    path === "/game-test/82" ||
                    path === "/game-test/83" ||
                    path === "/game-test/84" ||
                    path === "/game-test/85" ||
                    path === "/game-test/86" ||
                    path === "/game-test/90" ||
                    path === "/game-test/92" ||
                    path === "/game-test/93" ||
                    path === "/game-test/94" ||
                    path === "/game-test/87" ||
                    path === "/game-test/88" ||
                    path === "/game-test/89" ||
                    path === "/game-test/91" ||
                    path === "/game-test/95" ||
                    path === "/game-test/96" ||
                    path === "/game-test/97" ||
                    path === "/game-test/98" ||
                    path === "/game-test/99" ||
                    path === "/game-test/100" ||
                    path === "/game-test/101" ||
                    path === "/game-test/102" ||
                    path === "/game-test/103" ||
                    path === "/game-test/104" ||
                    path === "/game-test/105" ||
                    path === "/cake" ||
                    path === "/reports-podium/:reportId" ? null : (
                      <Header />
                    )}
                    <Component {...match} />
                  </Suspense>
                );
              }}
            />
          ))}
          <Route exact path="/home" render={() => <Redirect to="/" />} />
          <Route path="*" exact component={PageNotFound} />
        </Switch>
      </Fragment>
    );
  }
}

export default withRouter(connect(mapStateToProps)(App));
