import React, { useState, useEffect } from "react";
import { Button, Card, Col, Row, Modal, Spinner } from "react-bootstrap";
import axios from "axios";
import "../../../Assets/css/Navbar.scss";
import CustomToast from "../Toast";
import { Redirect } from "react-router-dom";

const CreateQuizTemplateModal = (props) => {
  const { showQuiz, handleClose } = props;
  const [redId, setredId] = useState(0);
  const [redtoggle, setredtoggle] = useState(false);

  const [templatess, settemplates] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const [show, setShow] = useState(false);
  const [showType, setShowType] = useState("denger");
  const [showMessage, setShowMessage] = useState("");

  useEffect(() => {
    axios.get("apiV1/test/?template=1").then((res) => {
      if (res.data) {
        settemplates(res.data.results);
        //   setquizModal(true);
      }
    });
  }, []);

  const createQuiz = (id) => {
    if (id === null) {
      setShow(true);
      setShowType("danger");
      setShowMessage("Please select template");
      return;
    }
    setLoading(true);
    axios
      .get(`apiV1/copy-test/${id}/`)
      .then((res) => {
        if (res.data) {
          setredId(res.data.id);
        }
      })
      .then(() => {
        setredtoggle(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  return (
    <>
      {redtoggle ? <Redirect to={`/create-quiz/${redId}`} /> : null}
      <Modal
        show={showQuiz}
        onHide={() => handleClose(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        // centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="border-0">
          <Modal.Title id="contained-modal-title-vcenter">
            Create a new Quiz
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-2 create-quiz-modal">
          <Row>
            {templatess &&
              templatess.length > 0 &&
              templatess.map((tempdata, index) => (
                <Col
                  lg={4}
                  md={6}
                  sm={6}
                  xs={6}
                  style={{ margin: "5px 0" }}
                  key={index}
                >
                  <Card
                    onClick={() => {
                      if (!isLoading) {
                        setSelectedTemplate(tempdata.id);
                      }
                    }}
                    className={`${
                      selectedTemplate === tempdata.id ? "border-primary" : ""
                    }`}
                  >
                    <img
                      src={tempdata.cover_image}
                      className="create-quiz-template-img"
                    />
                    <Card.Body className="p-2">{tempdata.test_name}</Card.Body>
                  </Card>
                </Col>
              ))}
          </Row>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-center">
          <Button
            variant="secondary"
            onClick={() => {
              handleClose(false);
              setSelectedTemplate(null);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={isLoading || selectedTemplate === null}
            onClick={() => createQuiz(selectedTemplate)}
          >
            {!isLoading ? (
              "Create"
            ) : (
              <>
                <Spinner size="sm" animation="border" /> Creating...
              </>
            )}
          </Button>
          {/* {isLoading ? (
              <Button disabled>
              <Spinner size="sm" animation="border" /> Creating...
              </Button>
              ) : (
                <Button onClick={() => setquizModal(false)}>Cancel</Button>
              )} */}
          {/* <div className="modal-bottom-btn-grp">
          </div> */}
        </Modal.Footer>
      </Modal>
      <CustomToast
        show={show}
        setShow={setShow}
        bg={showType}
        msg={showMessage}
      />
    </>
  );
};

export default CreateQuizTemplateModal;
